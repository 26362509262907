import React from "react";
export const Header = (props) => {
  return (
    <div className="intro">
      <header id="header">
        <div className="overlay">
          <div className="container">
            <div className="row">
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

