import { useState } from "react";
import React from "react";
import Swal from "sweetalert2";
import axios from "axios";
import ReactLoading from "react-loading";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [cargando1, setCargando] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  const envioDeCorreo = () => {
    // Obtén el formulario y sus campos
    const nombre = name;
    const correo = email;
  
    const mensaje = message;

    //Cambiar estos datos para cada pagina y su respectivo contacto
    const nombreProp = "mieltukano";
    const correoProp = "ventas@mieltukano.com";
    const pagina = "http://mieltukano.com/";

    const data = {
      nombre,
      correo,
      mensaje,
      nombreProp,
      correoProp,
      pagina,
    };

    // Realizar la solicitud POST utilizando Axios
    setCargando(true);
    axios
      .post("https://taeconta.com/api/public/api/correos/publicos", data)
      .then((response) => {
        console.log("Correo enviado con éxito:", response.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Correo enviado exitosamente",
          showConfirmButton: false,
          timer: 1500,
        });
        setCargando(false);
        // clearState(e)
      })
      .catch((error) => {
        console.error("Error al enviar el correo:", error);
        Swal.fire(
          "Error al enviar el correo",
          error.message + ", " + error.response.data.message,
          "error"
        );
        setCargando(false);
        // clearState(e)
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    envioDeCorreo()
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

   
  };
  return (
    <div>
      <div id="contact">
      <div className="honeycomb"></div>
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Ponte en contacto</h2>
                <p style={{color:'white'}}>
                  Por favor, completa el formulario a continuación para enviarnos un correo electrónico y nos pondremos en contacto contigo lo antes posible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nombre"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensaje"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                {cargando1 ?  <ReactLoading type='spin' color='white' /> :  <button type="submit" className="btn btn-custom btn-lg">
                Enviar mensaje
                </button>}
               
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h2 >Información de contacto</h2>
              <p>
              <span>
                  <i className="fa fa-clock" style={{color:'white', fontSize:'17px'}}></i> Horario de Servicio
                  
                 
                </span>
                <i  style={{color:'white', fontSize:'17px'}}> De lunes a sábado
                10 a 18 hrs</i>
                {/* <span></span>
                <i  style={{color:'white', fontSize:'17px'}}>Domingo 8am a 6pm</i> */}
                <span></span>
                <span>
                  <i className="fa fa-map-marker" style={{color:'white', fontSize:'17px'}}></i> Dirección
                </span>
                {props.data ? (
                  <a href="https://maps.app.goo.gl/JSb6PwFEL2tyViKp8" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                    {props.data.address}
                  </a>
                ) : (
                  "loading"
                )}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone" style={{color:'white', fontSize:'17px'}}></i> || <i className="fa fa-whatsapp" style={{color:'white', fontSize:'17px'}}></i> Teléfono

                </span>{" "}
                {props.data ? (
                 <a href={`tel:+52${props.data.phone}`} style={{color:'white', fontSize:'17px'}}>
                 {props.data.phone}
               </a>
                  
                ) : (
                  "loading"
                )}
                <span></span>
                {props.data ? (
                 <a href={`tel:+52${props.data.phone2}`} style={{color:'white', fontSize:'17px'}}>
                 {props.data.phone2}
               </a>
                  
                ) : (
                  "loading"
                )}
              </p>
              <p>
                <span>
                {/* <i className="fa fa-whatsapp" style={{color:'white', fontSize:'17px'}}></i> WhatsApp */}

                </span>{" "}
                {props.data ? (
                 <a href={`https://wa.me/+52${props.data.phone3}`} target="_blank" rel="noopener noreferrer" style={{color:'white', fontSize:'17px'}}>
                 {props.data.phone3}
               </a>
               
               
                  
                ) : (
                  "loading"
                )}
                <span></span>
                {props.data ? (
                <a href={`https://wa.me/+52${props.data.phone4}`} target="_blank" rel="noopener noreferrer" style={{color:'white', fontSize:'17px'}}>
                {props.data.phone4}
              </a>
              
                  
                ) : (
                  "loading"
                )}
              </p>
            </div>
        
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o" style={{color:'white', fontSize:'17px'}}></i> Email
                </span>{" "}
                {props.data ? (
                  <a href={`mailto:${props.data.email}`} style={{color:'white', fontSize:'17px'}}>{props.data.email}</a>
                ) : (
                  "loading"
                )}
              </p>
              
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a target="_blank" href={props.data ? props.data.facebook : "/"}>
                      <i className="fa-brands  fa-facebook fa-3x" ></i>
                    </a>
                  </li>
                  {/* <li>
                    <a target="_blank" href={props.data ? props.data.tiktok : "/"}>
                      <i className="fa-brands fa-tiktok fa-3x"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p style={{color:'white'}}> 
            &copy; Desarrollado por
            <a href="https://tecnologiasadministrativas.com/" rel="nofollow" target="_blank">
              {" "}TAE
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
