import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top" >
         <a className="navbar-brand page-scroll" href="#page-top" style={{ display: 'flex', alignItems: 'center'}} >
    <img src="img/tucan2.png" alt="Logo" style={{ width: '80px', height: '80px', marginLeft: '80px' }} />
  </a>
  <a className="navbar-brand page-scroll" href="#page-top" style={{ fontSize: '17px', color:"white", textTransform: 'uppercase', fontStyle: 'italic' }}>
  Tukano
  </a>
  <a className="navbar-brand page-scroll" href="#page-top" style={{ fontSize: '17px', color:"white", textTransform: 'uppercase', fontStyle: 'italic' }}>
  miel artesanal
  </a>
      <div className="container">
   
      <div className="navbar-header" >
  <button
    type="button"
    className="navbar-toggle collapsed"
    data-toggle="collapse"
    data-target="#bs-example-navbar-collapse-1"
  >
    <span className="sr-only">Navegacion</span>
    <span className="icon-bar"></span>
    <span className="icon-bar"></span>
    <span className="icon-bar"></span>
  </button>
 
</div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
    <li>
      <a href="#features" className="page-scroll" style={{ color: 'white' }}>
        Características
      </a>
    </li>
    <li>
      <a href="#about" className="page-scroll" style={{ color: 'white' }}>
        Acerca de
      </a>
    </li>
    <li>
      <a href="#services" className="page-scroll" style={{ color: 'white' }}>
        Servicios
      </a>
    </li>
    <li>
      <a href="#gallery" className="page-scroll" style={{ color: 'white' }}>
        Galería
      </a>
    </li>
    {/* <li>
      <a href="#testimonials" className="page-scroll" style={{ color: 'white' }}>
        Testimonios
      </a>
    </li>
    <li>
      <a href="#team" className="page-scroll" style={{ color: 'white' }}>
        Equipo
      </a>
    </li> */}
    <li>
      <a href="#contact" className="page-scroll" style={{ color: 'white' }}>
        Contacto
      </a>
    </li>
  </ul>
        </div>
      </div>
    </nav>
  );
};